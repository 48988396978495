import FeedbackContainer from "../FeedbackContainer/FeedbackContainer";
import FloatbuttonContainer from "../FloatbuttonContainer/FloatbuttonContainer";
import Header from "../Header/Header";
import Logoco from "../Logo/Logoco";
import RoomContainer from "../RoomContainer/RoomContainer";
import TipContainer from "../TipContainer/TipContainer";
import Loading from "../Loading/Loading";

import "./MainContainer.css";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PrivacyContainer from "../Privacypolicy/PrivacyContainer";
import TermofuseContainer from "../termofusepopup/TermofuseContainer";

const MainContainer = () => {
  const [data, setData] = useState("Tips Magnets");
  const [imageurl, setImageurl] = useState("Tips Magnets");
  const [links, setLinks] = useState("Tips Magnets");
  const [location, setLocation] = useState("Tips Magnets");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [linkArray, setLinkArray] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const { id } = useParams();
   
  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://backend.tipsmagnet.co/api/motels/${id}`
          );
             console.log(response.data)
             const cleanedPLinks = response.data.motelPLinks.replace(/\\/g, "");
             const cleanedLogoLinks = response.data.motelLogoLinks.replace(/\\/g, "");
          setData(response.data.motelName);
          setImageurl(cleanedLogoLinks);
          setLocation(response.data.motelLocation);
          const urlArray = cleanedPLinks.split(',');
          console.log(urlArray);

          // Specify the desired numbers and names
          const amounts = [5, 10, 20];
          const names = ["Product 1", "Product 2", "Product 3"];
          
          // Map to the desired JSON format
          const products = urlArray.map((url, index) => ({
            name: names[index],
            amount: amounts[index],
            link: url
          }));
          setLinkArray(products);

          setSelectedItem(products[0]);  // Set the first item as selected
          // Check the products array
          
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    } else {
      // Handle the case where ID is not provided
      setData("Tips Magnets"); // Default value
      setLoading(false);
    }
  }, [id]);

  if (loading) return <Loading />;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="MainContainer">
      <Header />
      <Logoco url={imageurl} />

      <div className="sc-dGFoNy gaIVUk">
        <p
          size="8"
          weight="bold"
          mt="12"
          align="center"
          className="sc-gTRrcP jnjpMY"
        >
{location}
        </p>
      </div>
      <p
        size="20"
        weight="bold"
        mb="8"
        align="center"
        className="sc-gTRrcP eqifuM"
      >
        Housekeeping Tip
      </p>
      <RoomContainer />
      <TipContainer
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
        LinkArray={linkArray}
      />
      <FeedbackContainer />
      <FloatbuttonContainer 
        amount={selectedItem.amount} 
        link={selectedItem.link} 
      />
      <PrivacyContainer/>
    </div>
  );
};

export default MainContainer;
